export const GlobeIcon = (
  props: React.SVGProps<SVGSVGElement>
): JSX.Element => {
  return (
    <svg
      className={props.className || 'w-3.5 h-3.5 fill-current'}
      viewBox='0 0 21 21'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.4792 1.425C5.47354 1.425 1.42505 5.47348 1.42505 10.4792C1.42505 15.4824 5.47113 19.575 10.4792 19.575C15.4849 19.575 19.5751 15.4848 19.5751 10.4792C19.5751 5.47108 15.4825 1.425 10.4792 1.425ZM11.2813 9.67704V3.24731C12.2071 3.88328 12.906 4.8749 13.3964 6.02498C13.8921 7.18773 14.1632 8.48691 14.2423 9.67704H11.2813ZM7.60372 6.02498C8.08666 4.89225 8.77199 3.91319 9.6771 3.27629V9.67704H6.75781C6.83693 8.48691 7.10797 7.18773 7.60372 6.02498ZM6.89086 3.95439C4.80497 5.0997 3.32178 7.22166 3.06054 9.67704H5.15264C5.27203 7.77402 5.81297 5.64651 6.89086 3.95439ZM15.8475 9.67704C15.7281 7.77661 15.1872 5.65115 14.0905 3.95921C16.1846 5.1049 17.6398 7.22329 17.8981 9.67704H15.8475ZM6.89463 17.0475C4.80588 15.9013 3.31993 13.7714 3.05999 11.2813H5.15262C5.27207 13.1887 5.81383 15.344 6.89463 17.0475ZM7.60413 14.9552C7.10803 13.7826 6.8369 12.4735 6.75779 11.2813H9.6771V17.7232C8.7729 17.0848 8.08745 16.0975 7.60413 14.9552ZM11.2813 17.7523V11.2813H14.2423C14.1632 12.4735 13.8921 13.7826 13.396 14.9552C12.9052 16.1151 12.2062 17.115 11.2813 17.7523ZM14.0866 17.0427C15.1863 15.3394 15.7281 13.1861 15.8475 11.2813H17.8987C17.6416 13.7698 16.1837 15.8962 14.0866 17.0427Z'
      />
    </svg>
  );
};
